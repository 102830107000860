import { useState } from "react";
import Navbar from "../../components/Navbar";
import ContactUs from "../../assets/img/contact.png";
import axios from "axios";
import { toast } from "react-toastify";

const Contact = () => {

  const [firstname, setfirstName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [reason, setReason] = useState("");
  
  const submit = async (e) => {
    e.preventDefault();

    const data = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: firstname,
        },
        {
          objectTypeId: "0-1",
          name: "email",
          value: email,
        },
        {
          objectTypeId: "0-1",
          name: "phone",
          value: phone,
        },
        {
          objectTypeId: "0-1",
          name: "message",
          value: message,
        },
        {
          objectTypeId: "0-1",
          name: "reason",
          value: reason,
        },
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: "I agree to allow Tap Tap to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive marketing communications from Tap Tap.",
            },
          ],
        },
      },
    };

   
    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/47185428/15509b5f-12ec-4288-aed4-cb44914b369b`,
      data
    );
    if (response.status === 200) {
      toast.success("Form submitted. Our team will get back to you shortly");
    } else {
      toast.error("Please fill input fields with (*)");
    }

    setEmail("");

    // window.location.reload();
  };

  return (
    <div>
      <Navbar />

      <section id="contact" className="h-full md:h-screen md:grid grid-cols-4">
        <div className="col-span-1 flex items-center mx-auto">
          <img src={ContactUs} alt="Tap tap contact" />
        </div>

        <div className="col-span-3 bg-black text-white flex">
          <div className="p-8 md:mx-20">
            <h1 className="mt-20 text-5xl font-bold mb-20 leading-tight">
              Contact Us
            </h1>

            <form className="gap-4 mt-16 flex flex-col w-full">
              <input
                type="text"
                placeholder="Name"
                className="p-4 font-bold text-3xl w-52 mt-4 md:w-80 bg-transparent"
                onChange={(e) => setfirstName(e.target.value)}
                required
              />
              <input
                type="email"
                placeholder="Email"
                className="p-4 font-bold text-3xl w-52 mt-4 md:w-80 bg-transparent"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
               <input
                type="text"
                placeholder="Phone Number"
                className="p-4 font-bold text-3xl w-52 mt-4 md:w-80 bg-transparent"
                onChange={(e) => setPhone(e.target.value)}
                required
              />

              <select
                for="reasons"
                className="px-3 mt-2 w-full opacity-50 h-[46px] bg-transparent"
                  onChange={(e) => setReason(e.target.value)}
              >
                <option value="">Reason for contact</option>
                <option value="Demo">Product Demo </option>
                <option value="Transaction/Payment Issues">Transaction/Payment Issues</option>
                <option value="Refund/Reversal Request">Refund/Reversal Request</option>
                <option value="Billing/Account Inquiries">Billing/Account Inquiries</option>
                <option value="Technical Suppor">Technical Support</option>
                <option value="Feature Request">Feature Request</option>
                <option value="Feedback/Complaint">Feedback/Complaint</option>
                <option value="General Inquiry">General Inquiry</option>
                <option value="Others">Others</option>
              </select>

              <textarea
                id="details"
                placeholder="Message"
                className="p-4 font-bold text-3xl rounded-md mt-4 w-full h-[200px] bg-transparent"
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>

              <button
                onClick={submit}
                className="bg-white px-2 h-9 w- md:h-12 md:py-3  md:px-6 ms-1 rounded-full text-black hover:scale-[1.02] duration-300"
              >
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
